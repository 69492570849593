import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { UserRepositoryType } from '../contracts';
let FullNameSetupModal = class FullNameSetupModal extends AbstractModal {
    constructor() {
        super(...arguments);
        this.data = { firstName: '', lastName: '' };
        this.isActing = false;
    }
    get isCompleted() {
        return typeof this.data.firstName === 'string' && typeof this.data.lastName === 'string' &&
            this.data.firstName.length > 0 && this.data.lastName.length > 0;
    }
    get me() {
        return this.$store.getters['profile/me'];
    }
    async saveDetails() {
        this.isActing = true;
        try {
            await this.meRepository.update(this.data);
            this.$store.commit('profile/SET_ME_DETAILS', this.data);
            if (typeof this.payload.onSuccess === 'function') {
                this.payload.onSuccess();
            }
            this.close();
        }
        catch (e) {
            console.warn(e);
        }
    }
};
__decorate([
    Inject(UserRepositoryType)
], FullNameSetupModal.prototype, "meRepository", void 0);
FullNameSetupModal = __decorate([
    Component({
        name: 'FullNameSetupModal',
        components: { Screen },
        created() {
            this.data = {
                firstName: this.me.firstName,
                lastName: this.me.lastName,
                nickname: this.me.nickname
            };
        }
    })
], FullNameSetupModal);
export { FullNameSetupModal };
export default FullNameSetupModal;
